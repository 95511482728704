import React, { createContext, useContext } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

// Create the context
const MediaQueryContext = createContext();

// Custom hook to use the context
export const useMediaQueryContext = () => useContext(MediaQueryContext);

// MediaQueryProvider component
export const MediaQueryProvider = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Detect if the screen size is mobile

  return (
    <MediaQueryContext.Provider value={isMobile}>
      {children}
    </MediaQueryContext.Provider>
  );
};
