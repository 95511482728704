import React from 'react';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Container, Box, TextField, IconButton, Grid } from '@mui/material';
import { AddCircle, Cancel } from '@mui/icons-material';
import blog_background from '../assets/home/blog_background.jpg';
import Typography from '@mui/material/Typography';
import { CreateBlogApi } from '../api/blogService';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .max(150, 'Title must be 150 characters or less'),
  summary: Yup.string()
    .required('Summary is required')
    .max(1500, 'Summary must be 150 characters or less'),
  description: Yup.string()
    .required('description is required')
    .max(1500, 'description must be 150 characters or less'),
});

const CreateBlog = () => {
  const handleSubmit = async (values, { setSubmitting }) => {
    const createblog = await CreateBlogApi(values);

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        title: '',
        summary: '',
        description: '',
        imageurl: '',
        sections: [''],
        reference: '',
        tags: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValidating, isValid, values }) => (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Form>
                  <div>
                    <img src={blog_background} style={{ width: '100%' }} />
                    <span>
                      <Typography component="h1" variant="h5">
                        Create Your Blog
                      </Typography>
                    </span>
                    <Field
                      as={TextField}
                      type="text"
                      id="title"
                      name="title"
                      fullWidth
                      required
                      label="Title"
                    />
                    <ErrorMessage
                      name="title"
                      style={{ color: 'red' }}
                      component="div"
                    />
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      type="text"
                      id="summary"
                      name="summary"
                      fullWidth
                      multiline
                      required
                      label="Summary"
                    />
                    <ErrorMessage
                      name="summary"
                      style={{ color: 'red' }}
                      component="div"
                    />
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      type="text"
                      id="description"
                      name="description"
                      fullWidth
                      multiline
                      required
                      label="Description"
                    />
                    <ErrorMessage
                      name="description"
                      style={{ color: 'red' }}
                      component="div"
                    />
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      type="text"
                      id="imageurl"
                      name="imageurl"
                      fullWidth
                      label="Imageurl"
                    />
                  </div>

                  <FieldArray name="sections">
                    {({ push, remove }) => (
                      <div>
                        <Grid item xs={12}>
                          {values.sections.map((_, index) => (
                            <div key={index}>
                              <Field name={`sections.${index}`}>
                                {({ field }) => (
                                  <TextField
                                    {...field}
                                    label={`Section ${index + 1}`}
                                    fullWidth
                                    margin="normal"
                                  />
                                )}
                              </Field>

                              {index > 0 && (
                                <IconButton
                                  onClick={() => remove(index)}
                                  color="secondary"
                                >
                                  <Cancel />
                                </IconButton>
                              )}
                            </div>
                          ))}

                          <IconButton
                            type="button"
                            onClick={() => push('')}
                            color="primary"
                          >
                            <AddCircle />
                          </IconButton>
                        </Grid>
                      </div>
                    )}
                  </FieldArray>

                  <div>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        type="text"
                        id="reference"
                        name="reference"
                        fullWidth
                        label="Reference"
                      />{' '}
                    </Grid>
                  </div>
                  <div>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        type="text"
                        id="tags"
                        name="tags"
                        fullWidth
                        label="Tags"
                      />{' '}
                    </Grid>
                  </div>
                  <button
                    style={{
                      background: 'navy',
                      color: '#fff',
                      border: '1px solid #eee',
                      borderRadius: '20px',
                      boxShadow: '5px 5px 5px #eee',
                      height: '40px',
                      width: '200px',
                      marginLeft: '50%',
                    }}
                    type="submit"
                    disabled={isSubmitting || isValidating || !isValid}
                  >
                    Submit
                  </button>
                </Form>
              </Grid>
            </Box>
          </Box>
        </Container>
      )}
    </Formik>
  );
};

export default CreateBlog;
