import React from 'react';
import {
  CircularProgress,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';
import './LoadingUI.css'; // CSS file for the dot animation

const LoadingUI = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect if the screen size is mobile

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        height: '50vh', // 50% height of the viewport
      }}
    >
      <Grid
        item
        style={{
          width: isMobile ? '75%' : '50%', // 75% for mobile, 50% for web
          textAlign: 'center',
        }}
      >
        {/* Circular Progress Icon */}
        <CircularProgress style={{ marginBottom: '20px' }} />

        {/* Fetching text with animated dots */}
        <Typography variant="h6">
          Fetching
          <Box component="span" className="dot-animation">
            .
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoadingUI;
