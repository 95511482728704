import React from 'react';
import { useAuth } from '../context/AuthContext';

const UserProfile = () => {
  const { state } = useAuth();
  console.log('Profile State', state);
  return (
    <div>
      Welcome {state.user?.firstName}
      <br />
      Here is your user profile.
    </div>
  );
};

export default UserProfile;
