import apiClient from './apiClient';

export const getBlogComments = async (blogId) => {
  try {
    const response = await apiClient.get(`/api/blogs/${blogId}/comment`);
    console.log('comments data', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching comments:', error);
    throw error;
  }
};
export const submitBlogComment = async (data, blogId, userId) => {
  const userData = loadUserFromLocalStorage();
  try {
    data.userName = userData.firstName;
    const response = await apiClient.post(`/api/blogs/${blogId}/comment`, data);
    console.log('API response:', response.data);
    alert('comment was added successfully');
  } catch (error) {
    console.error('Failed to add a comment', error);
  }
};

export const deleteBlogComment = async (commentId, blogId) => {
  try {
    const response = await apiClient.delete(`/api/blogs/${blogId}/comment`, {
      data: { commentId: commentId },
    });
    console.log(response.data);
    alert('comment deleted successfully');
  } catch (error) {
    console.error('Error deleting comment:', error);
  }
};
export const updateBlogComment = async (commentId, blogId, updatedComment) => {
  try {
    const response = await apiClient.put(`/api/blogs/${blogId}/commentupdate`, {
      commentId: commentId,
      comment: updatedComment,
    });
    console.log(response.data);
    alert('Comment updated successfully');
  } catch (error) {
    console.error('error while updating comment', error);
  }
};
