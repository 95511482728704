import { AddComment } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';

const handleCommentSubmit = () => {
  console.log('adding comments');
};

const BlogCommentsView = ({ blogCommentsData }) => {
  return (
    <Container>
      <FormControl fullWidth variant="standard" sx={{ mb: 2 }}>
        <InputLabel htmlFor="standard-adornment-addBlogComments">
          Add New Comment ...
        </InputLabel>
        <Input
          id="standard-adornment-addBlogComments"
          type="text"
          endAdornment={
            <InputAdornment position="end">
              <Tooltip title="Please sign in to add comment">
                <IconButton onClick={handleCommentSubmit}>
                  <AddComment />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
        />
      </FormControl>

      {blogCommentsData.length === 0 ? (
        <Box sx={{ p: 2, maxWidth: '1000px', mt: 0.5 }}>
          No comments available. Click above to post the first comment for the
          blog.
        </Box>
      ) : (
        <Box>
          {blogCommentsData.map((data, index) => (
            <Card key={index} sx={{ mb: 0.5 }}>
              <CardContent sx={{ py: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ color: 'text.secondary', fontSize: 14 }}
                >
                  By {data.userName}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ color: 'text.secondary', mb: 1, fontStyle: 'italic' }}
                >
                  {new Date(data.createdAt).toLocaleDateString()}
                  <br />
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  {data.comment}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      )}
    </Container>
  );
};

export default BlogCommentsView;
