import React from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
const images = [`${process.env.PUBLIC_URL}/images/home_page_hero_image1.jpg`];
export default function HeroSection() {
  // const [currentImage, setCurrentImage] = React.useState(0);
  // React.useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  //   }, 3000); // Change image every 3 seconds
  //   return () => clearInterval(interval);
  // }, []);
  return (
    <Box
      sx={{
        backgroundColor: '#d9d9d9', // Match navbar color
        component: 'section',
        // color: 'white',
        minHeight: '40vh',
        display: 'flex',
        alignItems: 'center',
        padding: 5,
      }}
    >
      <Grid container spacing={2}>
        {/* Left Side with Animated Images */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Box sx={{ width: '100%', maxWidth: 500, position: 'relative' }}>
            <AnimatePresence>
              <motion.img
                key={images[0]}
                src={images[0]}
                alt="Image 1"
                whileHover={{ scale: 1.1 }} // Scale up on hover
                transition={{ type: 'spring', stiffness: 300 }} // Smooth spring-like animation
                style={{
                  width: '100%',
                  position: 'relative',
                  top: 0,
                  left: 0,
                  borderRadius: '50px',
                }}
              />
            </AnimatePresence>
          </Box>
        </Grid>
        {/* Right Side with Caption and Button */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: { xs: 'center', md: 'left' },
            my: 10,
          }}
        >
          <Box>
            <Typography variant="h1" component="h1" gutterBottom>
              Reach Your Potential
            </Typography>
            <Typography variant="h6" component="p" paragraph>
              Customized theme-based interactive activities to engage yourself
              and grow your skills at the same time.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-grow' },
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                size="large"
                sx={{ marginTop: 2 }}
                href="/register"
              >
                Start Here
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
