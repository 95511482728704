import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchActivityData } from '../../api/activityService';

const ActivityList = () => {
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page')) || 1;

  // fetch the Activity data
  useEffect(() => {
    getActivites();
  }, [page]);

  const getActivites = async (grades, subject) => {
    setLoading(true);
    try {
      const data = await fetchActivityData(page, 10, grades, subject);
      setActivityData(data.data);
      const calculatedTotalPages = Math.ceil(data.totalCount / 10);
      setTotalPages(calculatedTotalPages > 0 ? calculatedTotalPages : 1);
    } catch (error) {
      console.error('Error fetching blog data:', error);
    } finally {
      setLoading(false);
    }
  };

  // handle page change through the object of pagination component
  const handlePageChange = (event, value) => {
    navigate(`/activites?page=${value}`);
  };

  return {
    activityData,
    totalPages,
    page,
    loading,
    handlePageChange,
    getActivites,
  };
};
export const findLabelPathRecursive = (subjects, inputLabel) => {
  for (let item of subjects) {
    if (item.label === inputLabel) {
      return item.label;
    }

    if (item.children && item.children.length > 0) {
      const childResult = findLabelPathRecursive(item.children, inputLabel);
      if (childResult !== null) {
        return item.label + ' > ' + childResult;
      }
    }
  }
  return null;
};

export default ActivityList;
