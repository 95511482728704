import React from 'react';
import Hero from '../components/home/Hero';
import ProductHowItWorks from '../components/home/ProductHowItWorks';
import Footer from '../components/footer/Footer';

const Home = () => {
  return (
    <div>
      <Hero />
      <ProductHowItWorks />
      <Footer />
    </div>
  );
};
export default Home;
