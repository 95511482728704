import React, { createContext, useReducer, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';

// Initial state
const initialState = {
  isAuthenticated: false,
  user: null,
};

// Reducer function - manages the authentication state
function authReducer(state, action) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
}

// Create Context
const AuthContext = createContext();

// Auth Provider component
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  // Effect to persist login state using localStorage
  useEffect(() => {
    const userSession = Cookies.get('userSession');
    if (userSession) {
      dispatch({ type: 'LOGIN', payload: JSON.parse(userSession) });
    }
  }, []);

  // Handle Login Success
  // const loginSuccess = (user, token) => {
  //   console.log('inside loginSuccess', user, token)
  //   // Store user and token in localStorage
  //   // localStorage.setItem('user', JSON.stringify(user));
  //   // localStorage.setItem('isAuthenticated', true);
  //   // localStorage.setItem('authToken', token); // Store the token in localStorage

  //   dispatch({
  //     type: LOGIN_SUCCESS,
  //     payload: JSON.parse(user),
  //   });
  //   console.log('after dispatch', state);
  // };

  // Handle logout
  // const logout = () => {
  //   // Clear localStorage and state
  //   // localStorage.removeItem('user');
  //   // localStorage.removeItem('isAuthenticated');
  //   // localStorage.removeItem('authToken');

  //   dispatch({ type: LOGOUT });
  // };

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use AuthContext - provides the authentication state and actions
export const useAuth = () => useContext(AuthContext);
