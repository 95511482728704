// import { loadUserFromLocalStorage } from '../redux/userAuth/userAuthSlice';
import apiClient from './apiClient';
import ENDPOINTS from './endpoints';

// fetch first ten blogs
export const fetchBlogData = async (page, limit = 10) => {
  try {
    const response = await apiClient.get(
      `${ENDPOINTS.LIST_BLOGS}?page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching blog data:', error);
    throw error;
  }
};

// fetch data using id
export const fetchBlogDetails = async (id) => {
  try {
    // const response = await axios.get(`/api/blogs/${id}`);
    const response = await apiClient.get(ENDPOINTS.BLOG_DETAIL(id));
    return response.data;
  } catch (error) {
    console.error('Error fetching blog details:', error);
    throw error;
  }
};

export const CreateBlogApi = async (values) => {
  const userData = loadUserFromLocalStorage(); //to get user data form local store
  try {
    values.author = userData.firstName;
    // Make API call using Axios
    console.log(values);
    // const response = await axios.post('/api/createblog', values);
    const response = await apiClient.post(ENDPOINTS.CREATE_BLOG);
    console.log('API response:', response.data);
    window.alert('Blog added successfully!');
  } catch (error) {
    console.error('API error:', error);
  }
};

export const incrementBlogViewCount = async (id) => {
  await apiClient.post(`/api/blogs/${id}/increment-view`);
};

// Increment blog like count
export const incrementBlogLikeCount = async (id) => {
  const response = await apiClient.post(`/api/blogs/${id}/increment-like`);
  return response.data;
};
