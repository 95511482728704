import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ErrorComponentView = ({ title, description }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50vh',
        textAlign: 'center',
        padding: '20px',
        backgroundColor: '#d9d9d9',
      }}
    >
      <Typography variant="h2" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {description}
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate('/')}
        sx={{ marginTop: '20px' }}
      >
        Click Here To Go Home
      </Button>
    </Box>
  );
};

export default ErrorComponentView;
