export const BASE_URL = process.env.REACT_APP_API_URL; //'http://localhost:9001'; //

const ENDPOINTS = {
  /* All the backend API endpoints will be listed here */
  /* Auth Urls */
  LOGIN: `${BASE_URL}/api/signin`,
  REGISTER: `${BASE_URL}/api/signup`,
  LOGOUT: `${BASE_URL}/api/logout`,
  /* Blog Urls */
  CREATE_BLOG: `${BASE_URL}/api/createblog`,
  LIST_BLOGS: `${BASE_URL}/api/listblogs`,
  BLOG_DETAIL: (id) => `${BASE_URL}/api/blogs/${id}`,
  /* Blog Comments Urls */
  COMMENTS: `${BASE_URL}/api/comments`,
  COMMENT_DETAIL: (id) => `${BASE_URL}/api/comments/${id}`,
  /* */
  SURVEY_GET: (id) => ``,
};

export default ENDPOINTS;
