import * as React from 'react';
import ActivityList from '../components/activity/Activities';
import { findLabelPathRecursive } from '../components/activity/Activities';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Divider,
  Tab,
  Grid,
  Pagination,
  Stack,
  Button,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';

export default function Products({ tabValue }) {
  const navigate = useNavigate();
  const {
    activityData,
    totalPages,
    page,
    loading,
    handlePageChange,
    getActivites,
  } = ActivityList();
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(tabValue);
  const Grades = [
    'Preschool',
    'K',
    '1st',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
    '7th',
    '8th',
  ];
  const [displayGrades, setDispalyGrades] = React.useState(Grades);
  const [filterToData, setFilterToData] = React.useState(null);
  const [treeFilter, setTreeFilter] = React.useState(null);
  const Subjects = [
    {
      id: 'Fine Arts',
      label: 'Fine Arts',
      children: [
        { id: 'Colors', label: 'Colors' },
        { id: 'Drawing & Painting', label: 'Drawing & Painting' },
        { id: 'Dance', label: 'Dance' },
        { id: 'Art History', label: 'Art History' },
      ],
    },
    {
      id: 'Foreign Language',
      label: 'Foreign Language',
      children: [
        { id: 'American Sign Language', label: 'American Sign Language' },
      ],
    },
    {
      id: 'Maths',
      label: 'Maths',
      children: [
        {
          id: 'Number Sence',
          label: 'Number Sence',
          children: [{ id: 'Prime Number', label: 'Prime Number' }],
        },
        { id: 'Addition', label: 'Addition' },
        { id: 'Subtraction', label: 'Subtraction' },
        { id: 'Multiplication', label: 'Multiplication' },
      ],
    },
  ];

  const gradeFilter = (stringToRemove) => {
    let filteredGrades = displayGrades.filter(
      (grade) => grade !== stringToRemove
    );
    const filteredArray = Grades.filter(
      (item) => !filteredGrades.includes(item)
    );
    setDispalyGrades(filteredGrades);
    setFilterToData(filteredArray);
  };
  const cancelGradeFilter = (stringToAdd, clearTreeFlag) => {
    if (clearTreeFlag == false) {
      const filteredArray = filterToData.filter((item) => item !== stringToAdd);
      const filteredGrades = Grades.filter(
        (item) => !filteredArray.includes(item)
      );
      setDispalyGrades(filteredGrades);
      setFilterToData(filteredArray);
    } else {
      setTreeFilter(null);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleFilterSubmit = (gradeData, subjectData, submitFlag) => {
    getActivites(gradeData, subjectData);
    if (submitFlag != true) {
      setFilterToData(null);
      setTreeFilter(null);
      setDispalyGrades(Grades);
    }
  };
  const handleClick = (activity) => {
    console.log('Clicked on activity', activity._id);
    navigate(`/products/${activity._id}`);
  };

  const handleTreeChange = (event, selectedValue) => {
    const selectedBreadCrumb = findLabelPathRecursive(Subjects, selectedValue);
    setTreeFilter(selectedBreadCrumb);
  };
  return (
    <>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Worksheets" value="1" />
            <Tab label="Activities" value="2" />
          </TabList>
        </Box>
        <Divider sx={{ width: '100%' }} />
        <Box sx={{ width: '100%', typography: 'body1' }}>
          {loading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                backdropFilter: 'blur(4px)',
                zIndex: 1000,
              }}
            >
              <CircularProgress color="secondary" />
            </Box>
          )}
          <TabPanel value="1">Worksheets</TabPanel>
          <TabPanel value="2">
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <p>Grades</p>
                {displayGrades.map((grade) => (
                  <Button
                    onClick={() => gradeFilter(grade)}
                    sx={{
                      border: '1px solid gray',
                      color: 'gray',
                      padding: '4px',
                      marginLeft: '3px',
                      fontWeight: '300',
                      textTransform: 'none',
                      marginBottom: '5px',
                    }}
                    key={grade}
                  >
                    {grade}
                  </Button>
                ))}
                <Divider sx={{ marginTop: '5px' }} />
                <p>Subject</p>
                <RichTreeView
                  items={Subjects}
                  onSelectedItemsChange={handleTreeChange}
                />
                <Divider sx={{ marginTop: '5px' }} />
              </Grid>
              <Grid item xs={10}>
                {filterToData != null
                  ? filterToData.map((data) => (
                      <Button
                        onClick={() => cancelGradeFilter(data, false)}
                        sx={{
                          color: 'skyblue',
                          border: '1px solid skyblue',
                          borderRadius: '8px',
                          textTransform: 'none',
                          marginRight: '3px',
                        }}
                      >
                        {data} <Close fontSize="small" />
                      </Button>
                    ))
                  : ''}

                {treeFilter != null ? (
                  <Button
                    onClick={() => cancelGradeFilter(null, true)}
                    sx={{
                      color: 'skyblue',
                      border: '1px solid skyblue',
                      borderRadius: '8px',
                      textTransform: 'none',
                      marginRight: '3px',
                    }}
                  >
                    {treeFilter} <Close fontSize="small" />
                  </Button>
                ) : (
                  ''
                )}
                {filterToData != null || treeFilter != null ? (
                  treeFilter != null || filterToData.length != 0 ? (
                    <>
                      <Button
                        sx={{
                          color: 'Green',
                          border: '1px solid Green',
                          borderRadius: '8px',
                          textTransform: 'none',
                          marginRight: '3px',
                          float: 'right',
                        }}
                        onClick={() =>
                          handleFilterSubmit(filterToData, treeFilter, true)
                        }
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={() => handleFilterSubmit()}
                        sx={{
                          color: 'Red',
                          border: '1px solid Red',
                          borderRadius: '8px',
                          textTransform: 'none',
                          marginRight: '3px',
                          float: 'right',
                        }}
                      >
                        Clear
                      </Button>
                    </>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}

                <Grid container spacing={2}>
                  {activityData.length > 0 ? (
                    activityData.map((activity) => (
                      <Grid item xs={4}>
                        <Card
                          sx={{ maxWidth: 345 }}
                          onClick={() => handleClick(activity)}
                        >
                          <CardMedia
                            component="img"
                            height="140"
                            // image="/static/images/cards/contemplative-reptile.jpg"
                            alt="green iguana"
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              {activity.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                maxHeight: 100,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {activity.description}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="h3" sx={{ justifyContent: 'center' }}>
                      No Activites Found
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Pagination */}
            <Stack
              spacing={2}
              style={{ marginTop: '20px', alignItems: 'center' }}
            >
              <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => handlePageChange(event, value)}
                variant="outlined"
                color="primary"
              />
            </Stack>
          </TabPanel>
        </Box>
      </TabContext>
    </>
  );
}
