// src/About.js

import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  Box,
  List,
  ListItem,
  ListItemText,
  CardMedia,
  Paper,
} from '@mui/material';
import { styled } from '@mui/system';

const RootContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const Section = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 0),
}));

const TeamCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
}));

const TeamAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(15),
  marginBottom: theme.spacing(2),
}));

const About = () => {
  const missionVisionContent = [
    {
      title: 'Our Mission',
      description:
        "Our mission is to unlock the boundless potential within every child. We believe that every kid is a unique, curious, and imaginative individual, and it's our goal to nurture their growth through engaging, educational, and fun activities.",
      imageUrl: 'https://via.placeholder.com/100',
    },
    {
      title: 'Our Vision',
      description:
        'We envision a world where children have access to a rich tapestry of learning experiences, fostering their creativity, critical thinking, and love for exploration. By providing a diverse range of activities, we aim to ignite their curiosity, broaden their horizons, and empower them to become lifelong learners.',
      imageUrl: 'https://via.placeholder.com/100',
    },
  ];

  const teamMembers = [
    {
      name: 'Rakesh Shah',
      role: 'FOUNDER',
      description: 'Description',
      imageUrl: 'https://via.placeholder.com/100',
    },
    {
      name: 'Lakshman',
      role: 'Technology Enthusiast',
      description: 'Description here',
      imageUrl: 'https://via.placeholder.com/150',
    },
    {
      name: 'Satya Barrik',
      role: 'Technology Enthusiast',
      description: 'Description here',
      imageUrl: 'https://via.placeholder.com/150',
    },
  ];

  const goals = [
    'Encourage Exploration and Discovery: We strive to create an environment where kids can freely explore their interests, ask questions, and discover new passions. Our activities are designed to spark their imagination and stimulate their natural curiosity.',
    'Promote Holistic Development: We believe in nurturing the whole child – their cognitive, physical, social, and emotional well-being. Our activities cater to various developmental areas, ensuring a well-rounded growth experience.',
    'Foster Creativity and Innovation: In a rapidly changing world, we recognize the importance of cultivating creativity and innovative thinking. Our activities encourage kids to think outside the box, embrace their unique perspectives, and develop problem-solving skills.',
    'Inspire a Love for Learning: We aim to instill a lifelong love for learning in every child. By making learning fun, engaging, and relevant to their interests, we hope to spark a passion that will continue to fuel their curiosity and thirst for knowledge.',
    "Empower Parents and Caregivers: We understand the importance of parental and caregiver involvement in a child's development. Our platform provides resources, tools, and guidance to support and empower families in nurturing their children's growth.",
  ];

  return (
    <RootContainer>
      <Typography variant="h2" align="center" gutterBottom>
        About Us
      </Typography>

      <Box sx={{ p: 2 }}>
        {missionVisionContent.map((item, index) => (
          <Paper elevation={0} sx={{ mb: 3, p: 2 }} key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <img
                  src={item.imageUrl}
                  alt={item.title}
                  style={{ width: '100%', height: 'auto' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h4" gutterBottom>
                  {item.title}
                </Typography>
                <Typography variant="body1">{item.description}</Typography>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Box>

      <Paper elevation={0} sx={{ mb: 3, p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <img src="" alt="Goals" style={{ width: '100%', height: 'auto' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              Our Goals
            </Typography>
            {goals.map((goal, index) => (
              <ListItem key={index}>
                <ListItemText primary={goal} />
              </ListItem>
            ))}
          </Grid>
        </Grid>
      </Paper>

      <Section>
        <Typography variant="h4" gutterBottom>
          Our Team
        </Typography>
        <Grid container spacing={3}>
          {teamMembers.map((member) => (
            <Grid item xs={12} md={4} key={member.name}>
              <TeamCard>
                <TeamAvatar src={member.imageUrl} />
                <CardContent>
                  <Typography variant="h6">{member.name}</Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {member.role}
                  </Typography>
                  <Typography variant="body2">{member.description}</Typography>
                </CardContent>
              </TeamCard>
            </Grid>
          ))}
        </Grid>
      </Section>
    </RootContainer>
  );
};

export default About;
