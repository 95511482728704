import * as React from 'react';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQueryContext } from '../../context/MediaQueryProvider';
import { useAuth } from '../../context/AuthContext';
import Cookies from 'js-cookie';
import apiClient from '../../api/apiClient';
import ENDPOINTS from '../../api/endpoints';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const isMobile = useMediaQueryContext();
  const { state, dispatch } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      console.log('logging out');
      const response = await apiClient.get(ENDPOINTS.LOGOUT);
      Cookies.remove('userSession');
      console.log('logout response', response);
      dispatch({
        type: 'LOGOUT',
      });
    } catch (error) {
      console.error('unable to logout');
    }
  };

  const links = [
    { title: 'Product', path: '/products' },
    // { title: 'Services', path: '/services' },
    { title: 'Blogs', path: '/blogs' },
    { title: 'About', path: '/about' },
  ];

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: '#d9d9d9' }}>
        <Toolbar>
          {isMobile ? (
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <IconButton
                edge="start"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                sx={{
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    boxSizing: 'circle',
                    borderRadius: '10px', // round edges
                    padding: 2,
                    backgroundColor: '#f9d9d2',
                    height: '90vh', // 50% of the viewport height
                    top: '48px', // Align Drawer below the Navbar
                    overflowY: 'auto',
                    opacity: 0.7,
                  },
                }}
              >
                <List>
                  {links.map((link) => (
                    <ListItemButton
                      key={link.title}
                      onClick={handleDrawerToggle}
                      href={link.path}
                    >
                      <ListItemText primary={link.title} />
                    </ListItemButton>
                  ))}
                </List>
              </Drawer>
              <Link href="/">
                <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/knoloom_home_logo.png`}
                    alt="Logo"
                    style={{ height: '50px' }}
                  />
                </Box>
              </Link>

              {state.isAuthenticated ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/signin')}
                >
                  Login
                </Button>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                mx: 10,
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Link href="/">
                <Box sx={{ textAlign: 'left' }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/knoloom_home_logo.png`}
                    alt="Logo"
                    style={{ height: '50px' }}
                  />
                </Box>
              </Link>

              <div
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {links.map((link) => (
                  <Button key={link.title} href={link.path}>
                    {link.title}
                  </Button>
                ))}
              </div>
              {state.isAuthenticated ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/signin')}
                >
                  Login
                </Button>
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};
export default Navbar;
