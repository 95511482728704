import { Box, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingUI from '../loading/LoadingUI';
import {
  BLOGS_DETAILS_ERROR_MESSAGE,
  BLOGS_DETAILS_ERROR_TITLE,
} from '../../constants/errorMessages';

import {
  fetchBlogDetails,
  incrementBlogViewCount,
} from '../../api/blogService';
import { getBlogComments } from '../../api/commentService';

import ErrorComponentView from '../error/ErrorComponentView';
import BlogDetailsTemplate from './BlogDetailsTemplate';
import BlogCommentsView from './comments/BlogCommentsView';

const BlogDetailView = () => {
  const { id } = useParams();
  const [blogDetailData, setBlogDetailData] = useState(null);
  const [blogCommentsData, setBlogComments] = useState(null);
  const [blogLoading, setBlogLoading] = useState(true);
  const [commentsLoading, setCommentsLoading] = useState(true);
  const [fetchBlogError, setFetchBlogError] = useState(false);
  const [fetchBlogCommentError, setFetchBlogCommentError] = useState(false);

  useEffect(() => {
    const invokeBlogDetailsApi = async () => {
      try {
        const blogDetailResponse = await fetchBlogDetails(id);
        setBlogDetailData(blogDetailResponse.data);
        setBlogLoading(false);
      } catch (error) {
        console.error('Failed to fetch blog details:', error);
        setBlogLoading(false);
        setFetchBlogError(true);
      }
    };
    console.log('Blog Details:', blogDetailData);
    invokeBlogDetailsApi();

    // If Blog Detail is loaded, increase the view count
    if (blogDetailData && blogDetailData.title) {
      try {
        incrementBlogViewCount(id);
        console.log('blog view count successful');
      } catch (error) {
        console.error('Blog view count not incremented', error);
      }
    }
  }, [id]);

  useEffect(() => {
    const invokeBlogCommentsApi = async () => {
      try {
        const blogCommentsResponse = await getBlogComments(id);
        setBlogComments(blogCommentsResponse.data);
        setCommentsLoading(false);
      } catch (error) {
        console.error(
          `Failed to fetch blog comments for blog Id: ${id}`,
          error
        );
        setCommentsLoading(false);
        setFetchBlogCommentError(true);
      }
    };
    console.log('Blog Comment Details:', blogCommentsData);
    invokeBlogCommentsApi();
  }, [id]);

  return (
    <Container>
      {blogLoading ? (
        <LoadingUI />
      ) : fetchBlogError ? (
        <ErrorComponentView
          title={BLOGS_DETAILS_ERROR_TITLE}
          description={BLOGS_DETAILS_ERROR_MESSAGE}
        />
      ) : (
        <BlogDetailsTemplate blogDetailData={blogDetailData} />
      )}

      {/* Load comments only when blog fetch is successfull */}
      {!fetchBlogError &&
        (commentsLoading ? (
          <LoadingUI />
        ) : fetchBlogCommentError ? (
          <ErrorComponentView
            title={BLOGS_DETAILS_ERROR_TITLE}
            description={BLOGS_DETAILS_ERROR_MESSAGE}
          />
        ) : (
          <BlogCommentsView blogCommentsData={blogCommentsData} />
        ))}
    </Container>
  );
};

export default BlogDetailView;
