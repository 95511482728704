import { Box, Typography } from '@mui/material';
import React from 'react';
import BlogSectionView from './BlogSectionView';

import kidsPlayingImage from '../../assets/home/kids_playing.jpg';

const commonStyles = {
  bgcolor: 'background.paper',
  border: 1,
  borderColor: 'text.primary',
  margin: 'auto',
};

const BlogDetailsTemplate = ({ blogDetailData }) => {
  return (
    <Box
      sx={{
        ...commonStyles,
        p: 2,
        maxWidth: '1000px',
        mt: 0.5,
        borderTop: 0,
        borderColor: 'grey.200',
      }}
    >
      {/* Blog Main Title */}
      <Typography variant="h2" component="h1" gutterBottom align="center">
        {blogDetailData.title}
      </Typography>

      {/* Blog Image */}
      {blogDetailData.imageURL && (
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <img
            src={blogDetailData.imageURL || kidsPlayingImage}
            alt={blogDetailData.title}
            style={{
              width: '100%',
              height: 'auto',
              maxHeight: '500px',
              borderRadius: '8px',
            }}
          />
        </Box>
      )}

      {/* Blog Main Summary */}
      <Typography variant="subtitle1" align="justify" sx={{ mb: 3 }}>
        {blogDetailData.summary}
      </Typography>

      {/* Blog Sections */}
      {blogDetailData.sections &&
        blogDetailData.sections.length > 0 &&
        blogDetailData.sections.map((section, index) => (
          <BlogSectionView key={index} blogSectionData={section} />
        ))}

      {/* Blog Resource Tips */}
      {blogDetailData.resourceTips && (
        <Typography
          variant="subtitle2"
          align="center"
          sx={{ mb: 3 }}
          fontStyle="italic"
        >
          {blogDetailData.resourceTips}
        </Typography>
      )}

      {/* Blog Disclaimer */}
      {blogDetailData.disclaimer && (
        <Typography
          variant="subtitle2"
          align="center"
          sx={{ mb: 3 }}
          fontStyle="italic"
          fontWeight="bold"
          backgroundColor="gray"
        >
          {blogDetailData.disclaimer}
        </Typography>
      )}
    </Box>
  );
};

export default BlogDetailsTemplate;
