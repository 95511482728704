import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Pagination,
  Stack,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import kidsPlayingImage from '../../assets/home/kids_playing.jpg';
import { motion } from 'framer-motion';
import { useMediaQueryContext } from '../../context/MediaQueryProvider';

export const formatCounts = (count) => {
  if (count < 1000) return count;
  if (count < 1000000) return (count / 1000).toFixed(1) + 'K';
  return (count / 1000000).toFixed(1) + 'MM';
};

// Individual BlogItem component.
const BlogItem = ({ blog, descriptionLength }) => {
  const {
    title,
    author,
    createdAt,
    viewCount,
    likeCount,
    commentIds,
    summary,
    imageUrl,
  } = blog;

  return (
    <Card elevation={1} sx={{ display: 'flex', mb: 2 }}>
      {/* Animate the image, in the left side, using framer motion */}
      <Box
        component={motion.div}
        sx={{ width: '40%' }}
        whileHover={{ scale: 1.1 }} // Zoom in on hover
        initial={{ scale: 1 }} // Start at normal scale
        transition={{ type: 'spring', stiffness: 300 }} // Animation duration
      >
        <CardMedia
          component="img"
          image={imageUrl && imageUrl[0] ? imageUrl[0] : kidsPlayingImage}
          alt={title}
          sx={{ height: '100%', width: '100%', objectFit: 'cover' }}
        />
      </Box>
      {/* Right side of the Blog Item, which has title, likes, comments, etc... */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '60%',
          backgroundColor: '#d9d9d9',
        }}
      >
        <CardContent>
          {/* Blog Title */}
          <Typography variant="h6">{title}</Typography>

          {/* Author and Date */}
          <Typography variant="body2" sx={{ opacity: 0.7 }}>
            {author} • {new Date(createdAt).toLocaleDateString()}
          </Typography>

          {/* Views, Likes, and Comments */}
          <Grid container spacing={1} sx={{ opacity: 0.5 }}>
            <Grid item>
              <Typography variant="body2">
                👁️ {formatCounts(viewCount)} •
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                👍 {formatCounts(likeCount)} •
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                💬 {formatCounts(commentIds.length)}
              </Typography>
            </Grid>
          </Grid>

          {/* Blog Description (Truncated) */}
          <Typography variant="body1" sx={{ mt: 2 }}>
            {summary.length > descriptionLength
              ? summary.slice(0, descriptionLength) + '...'
              : summary}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};

const BlogListView = ({ blogData, blogsPerPage }) => {
  // Initial setup
  const isMobile = useMediaQueryContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [descriptionLength] = useState(100);
  // Mobile blog sorting based on selection.
  const [sortedBlogs, setSortedBlogs] = useState([]);
  const [sortType, setSortType] = useState('createdDate');

  useEffect(() => {
    // Sort blogs based on the selected sortType
    const sorted = blogData.sort((a, b) => {
      if (sortType === 'likes') {
        return b.likeCount - a.likeCount;
      }
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setSortedBlogs(sorted);
  }, [sortType]);

  // Pagination calculations
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  // Sort the blogs based on likes and then slice it.

  const currentBlogs = (
    isMobile ? sortedBlogs : blogData.sort((a, b) => b.likeCount - a.likeCount)
  ).slice(indexOfFirstBlog, indexOfLastBlog);
  const totalPages = Math.ceil(blogData.length / blogsPerPage);

  // handle page change through the object of pagination component
  // On page select, scroll to top of the window.
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };

  return (
    <Grid container spacing={1} style={{ paddingTop: 20 }}>
      {/* Sorting blogs only available on mobile */}
      {isMobile && (
        // Sorting Dropdown
        <Grid item>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
            <InputLabel>Sort by</InputLabel>
            <Select
              label="Sort by"
              value={sortType}
              onChange={(e) => setSortType(e.target.value)}
            >
              <MenuItem value="createdDate">Date Created</MenuItem>
              <MenuItem value="likes">Likes</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      {/* Left side on web view */}
      <Grid item xs={12} md={8}>
        {/* If showing Ads, can be added here */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mx: isMobile ? 2 : 5,
          }}
        >
          {currentBlogs.map((currentBlog) => (
            <Link
              to={`/blogs/${currentBlog._id}`}
              style={{ textDecoration: 'none' }}
              key={currentBlog._id}
            >
              <BlogItem
                blog={currentBlog}
                descriptionLength={descriptionLength}
              />
            </Link>
          ))}
        </Box>
        <Stack spacing={2} style={{ marginTop: '20px', alignItems: 'center' }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="primary"
            size={isMobile ? 'small' : 'large'}
          />
        </Stack>
      </Grid>

      {/* Right Column of the Blog Page: Blogs sorted by creation date - displayed only on desktop browser. */}
      {!isMobile && (
        <Grid item md={4}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: '80px',
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              color="success"
              align="center"
              sx={{ backgroundColor: '#d9d9d9' }}
            >
              Recent Posts
            </Typography>
            {blogData
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .slice(0, 4)
              .map((blog) => (
                <Link
                  to={`/blogs/${blog._id}`}
                  style={{ textDecoration: 'none' }}
                  key={blog._id}
                >
                  <BlogItem blog={blog} descriptionLength={25} />
                </Link>
              ))}
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default BlogListView;
