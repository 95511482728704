import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import kidsPlayingImage from '../../assets/home/kids_playing.jpg';
import { useMediaQueryContext } from '../../context/MediaQueryProvider';

const BlogSectionView = ({ blogSectionData }) => {
  const {
    sectionTitle,
    sectionContent,
    sectionImageUrl,
    imagePosition = 'left',
    subSections,
    tips,
    funFact,
  } = blogSectionData;

  // Image position
  const isImageRight = imagePosition === 'right';
  const isImageLeft = imagePosition === 'left';
  const isImageTop = imagePosition === 'top';

  // Media query
  const isMobile = useMediaQueryContext();

  // Below line and it's corresponding usage to be removed - whenever possible.
  const newSectionImageUrl =
    sectionImageUrl === undefined ? kidsPlayingImage : sectionImageUrl;

  return (
    <Box sx={{ p: 3, maxWidth: '1000px', margin: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        {sectionTitle}
      </Typography>

      {/* Image on top */}
      {newSectionImageUrl && (isImageTop || isMobile) && (
        <Box sx={{ mb: 2, textAlign: 'center' }}>
          <img
            src={newSectionImageUrl}
            alt={sectionTitle}
            style={{ width: '100%', height: 'auto', maxHeight: '300px' }}
          />
        </Box>
      )}

      {/* Text and image side-by-side */}
      <Grid
        container
        spacing={2}
        sx={{ alignItems: 'center', display: 'flex' }}
      >
        {/* Image on left or right */}
        {newSectionImageUrl && !isMobile && isImageLeft && (
          <Grid id="blogSectionImageGrid" item xs={12} md={4}>
            <img
              src={newSectionImageUrl}
              alt={sectionTitle}
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '100%',
                objectFit: 'cover',
              }}
            />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          md={newSectionImageUrl && (isImageLeft || isImageRight) ? 8 : 12}
        >
          <Typography variant="body1">{sectionContent}</Typography>
          {/* Display SubSection, if any */}
          {subSections.length > 0 &&
            subSections.map((subSection, index) => (
              <Box key={index} sx={{ mt: 2 }}>
                <Typography variant="h5" fontWeight="bold">
                  {subSection.subSectionTitle}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {subSection.subSectionContent}
                </Typography>
                {subSection.subSectionTips && (
                  <Typography variant="body2" fontStyle="italic">
                    {subSection.subSectionTips}
                  </Typography>
                )}
              </Box>
            ))}
          {/* Display fun fact, if any */}
          {funFact && (
            <Typography variant="body2" fontStyle="italic" sx={{ mt: 2 }}>
              {funFact}
            </Typography>
          )}
        </Grid>

        {newSectionImageUrl && !isMobile && isImageRight && (
          <Grid item xs={12} md={4}>
            <img
              src={newSectionImageUrl}
              alt={sectionTitle}
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '100%',
                objectFit: 'cover',
              }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default BlogSectionView;
