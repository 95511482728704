import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import CreateBlog from './CreateBlog';
import CreateIcon from '@mui/icons-material/Create';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function SideBar(props) {
  const [showCreateBlog, setShowCreateBlog] = React.useState(false);

  const handleCreateBlogClick = () => {
    setShowCreateBlog(true);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Toolbar />
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Item sx={{ marginBottom: 2 }}>
            <Typography
              onClick={handleCreateBlogClick}
              sx={{ cursor: 'pointer' }}
            >
              <CreateIcon />
              Create New Blog
            </Typography>
          </Item>
          <Item>
            <Typography
              onClick={handleCreateBlogClick}
              sx={{ cursor: 'pointer' }}
            >
              <CreateIcon />
              Create New Activity
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={10}>
          <Item>
            {showCreateBlog ? (
              <CreateBlog />
            ) : (
              <Typography paragraph>
                Welcome to the admin panel! Select an option from the menu to
                get started.
              </Typography>
            )}
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SideBar;
