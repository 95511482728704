import * as React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Box, Grid, ButtonBase, Button } from '@mui/material';
import { ContentCut } from '@mui/icons-material';
import { BookmarkBorder } from '@mui/icons-material';
import { fetchActivityDetails } from '../../api/activityService';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

export default function ActivityDetailView() {
  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchActivityById();
      } catch (error) {
        console.error('Error fetching activity data:', error);
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();
  const [activity, setActivity] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [backButton, setBackButton] = React.useState(false);
  const handleBackButton = () => {
    navigate('/products');
  };

  const fetchActivityById = async () => {
    setLoading(true);
    try {
      const data = await fetchActivityDetails(id);
      console.log('id', data.data);
      setActivity(data.data);
    } catch (error) {
      console.error('Error fetching activity data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              backdropFilter: 'blur(4px)',
              zIndex: 1000,
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        )}
        <Button
          onClick={handleBackButton}
          sx={{
            float: 'right',
            color: 'blue',
            border: '1px solid blue',
            borderRadius: '8px',
            textTransform: 'none',
            marginRight: '3px',
            marginTop: '7px',
          }}
        >
          Back
        </Button>
        <Typography variant="h4" fontWeight="500">
          <ContentCut sx={{ fontSize: 25, marginRight: 1 }} />
          Activity
        </Typography>
        <Typography variant="h3" sx={{ color: 'skyblue', marginTop: 2 }}>
          {activity != null ? activity.title : ''}
        </Typography>
        <Grid container spacing={2}>
          <Grid item>
            <ButtonBase sx={{ width: 400, height: 300 }}>
              Image
              {/* <Img alt="complex" src="/static/images/grid/complex.jpg" /> */}
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom component="div">
                  {activity != null ? activity.description : ''}
                </Typography>
                <Button
                  sx={{
                    marginTop: 3,
                    backgroundColor: 'skyblue',
                    '&:hover': { backgroundColor: 'deepskyblue' },
                    borderRadius: 20,
                  }}
                >
                  Download Free Activity
                </Button>

                <Typography
                  sx={{
                    marginTop: 2,
                    color: 'blue',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  <BookmarkBorder
                    sx={{ margin: '-5px', paddingRight: '5px' }}
                  />
                  Add to collection
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginTop: 2 }}
                >
                  Grade
                </Typography>
                {activity != null &&
                activity.tags.gradeLevel != undefined &&
                activity.tags.gradeLevel.length > 0 ? (
                  activity.tags.gradeLevel.map((grade) => (
                    <Button
                      sx={{
                        color: 'skyblue',
                        border: '1px solid skyblue',
                        borderRadius: '20px',
                        fontWeight: 500,
                      }}
                    >
                      {grade}
                    </Button>
                  ))
                ) : (
                  <Typography>No grades selected</Typography>
                )}
              </Grid>
              <Grid item>
                <Typography
                  sx={{ cursor: 'pointer', marginTop: 2 }}
                  variant="body2"
                >
                  Subject
                </Typography>
                {activity != null && activity.tags.subject != undefined ? (
                  <Button
                    sx={{
                      color: 'skyblue',
                      border: '1px solid skyblue',
                      borderRadius: '20px',
                      fontWeight: 500,
                    }}
                  >
                    {activity.tags.subject}
                  </Button>
                ) : (
                  <Typography>No subject selected</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
